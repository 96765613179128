<template>
  <div v-if="notifyType === ''">
    <WButton link sm
      @click.stop
      v-clipboard:copy="postScript"
      v-clipboard:success="onCopyClipboard"
      v-tooltip.top-center="{
        content: `${postScript ? postScript : $t('general.no')}`
      }"
    >
      {{ $t('rules.triggerScript') }}
      <i class="fa fa-clipboard"/>
    </WButton>
  </div>

  <div v-else-if="notifyType === 'telegram'">
    <p class="caption">
      {{ $t('rules.notifySettingsForTableForTelegramToChatId') }}:
      {{ chatId }}
    </p>
    <WButton link sm
      @click.stop.prevent="handleClickShowMoreButton"
    >
      {{ $t('rules.notifySettingsForTableShowMore') }}
    </WButton>
  </div>

  <div v-else-if="notifyType === 'email'">
    <p class="caption">
      {{ $t('rules.notifySettingsForTableForEmailToAddress') }}:
      {{ toEmailAdress }}
    </p>
    <WButton link sm
      @click.stop.prevent="handleClickShowMoreButton"
    >
      {{ $t('rules.notifySettingsForTableShowMore') }}
    </WButton>
  </div>

  <div v-else-if="notifyType === 'mqtt'">
    <p class="caption">
      {{ $t('rules.notifySettingsForTableForMQTTToTopic') }}:
      {{ topic }}
    </p>
    <WButton link sm
      @click.stop.prevent="handleClickShowMoreButton"
    >
      {{ $t('rules.notifySettingsForTableShowMore') }}
    </WButton>
  </div>

  <div v-else>-</div>
</template>

<script>
/**
 * компонент - ячейка parms.
 * компонент локальный: используется только в RulesTable.vue
 * @component
 */

import VueNotifications from 'vue-notifications';

export default {
  name: 'ParamsCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    notifyType() {
      return this.props.row.params.notify_type;
    },
    postScript() {
      return this.props.row.params.post_script;
    },
    chatId() {
      return this.props.row.params.notify_settings.chat_id;
    },
    toEmailAdress() {
      return this.props.row.params.notify_settings.to;
    },
    topic() {
      return this.props.row.params.notify_settings.topic;
    }
  },
  methods: {
    onCopyClipboard() {
      VueNotifications.success({
        message: this.$t('monitor.notificationRuleCopyingScriptWasSuccessful')
      });
    },
    handleClickShowMoreButton() {
      this.$emit('on-show-more-button-click');
    }
  }
};
</script>

<style lang="css" scoped>
.caption {
  padding: 0 8px;
  margin: 0;
}
</style>
