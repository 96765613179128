<template>
  <span :class="[!props.row.model && 'muted']">
    {{ props.row.model ? props.row.model : '-' }}
  </span>
</template>

<script>
/**
 * компонент - ячейка model.
 * компонент локальный: используется только в RulesAPsTable.vue
 * @component
 */

export default {
  name: 'ModelCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.muted {
  color: var(--gray-light);
}
</style>
