<template>
  <WTable
    style="height: calc(70vh)"
    max-height="calc(70vh - 140px)"
    :columns="columns"
    :rows="rows"
    :isDisabled="isDisabled"
    :bordered="false"
    :withToggleColumnVisibilityMode="!isOneColMode"
    :line-numbers="!isOneColMode"
    :row-style-class="rowStyleClassFn"
    :sort-options="{
      enabled: true,
    }"
    :search-options="{
      enabled: true,
      skipDiacritics: true,
      placeholder: `${$t('general.search')}`,
      externalQuery: searchQuery
    }"
    :pagination-options="{
      enabled: true,
      remoteMode: true,
      mode: 'pages', // pages, records
      position: 'bottom',
      perPage: 10,
      perPageDropdownEnabled: true,
      perPageDropdown: [5, 10, 20, 40, 50],
      dropdownAllowAll: true,
      jumpFirstOrLast : true,
      firstLabel: $t('general.pagination.firstPage'),
      lastLabel: $t('general.pagination.lastPage'),
      nextLabel: $t('general.pagination.nextPage'),
      prevLabel: $t('general.pagination.prevPage'),
      rowsPerPageLabel: $t('general.pagination.rowsPerPage'),
      pageLabel: $t('general.pagination.page'),
      ofLabel: $t('general.pagination.of'),
      allLabel: $t('general.pagination.all')
    }"
    :isLoading.sync="isDataLoading"
    @update:columns="handleUpdateColumns"
    @on-selected-rows-change="handleSelectedRowsChange"
    @on-select-all="handleSelectedAllRowsChange"
    @on-cell-click="handleCellClick"
    @on-page-change="handlePageChange"
    @on-per-page-change="handlePerPageChange"
    @on-sort-change="onSortChange"
  >

    <template v-slot:table-menu>
      <slot name="table-menu"></slot>
    </template>

    <template v-slot:emptystate>
      <slot name="emptystate"></slot>
    </template>

    <template v-slot:table-row="props">
      <template v-if="props.column.field === 'name'">
        <NameCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'type'">
        <TypeCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'data'">
        <DataCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'notifyType'">
        <NotifyTypeCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'params'">
        <ParamsCell
          :props="props"
          @on-show-more-button-click="handleShowMoreButtonClick(props.row)"
        />
      </template>

      <template v-else-if="props.column.field === 'action'">
        <ActionCell
          :props="props"
          @on-delete-click="handleDeleteRule(props.row)"
        />
      </template>

      <template v-else>
        {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-' }}
      </template>
    </template>

    <template v-slot:table-actions-bottom v-if="!isOneColMode">
      <slot name="table-actions-bottom"></slot>
    </template>

  </WTable>
</template>

<script>
/**
 * компонент - таблица статистики по rules.
 * компонент локальный: используется только в Rules.vue
 * подробнее по настройкам и пропсам - см компонент WTable.vue
 * @component
*/

import {
  generateTableColumns,
  generateTableRows
} from './config';

import {
  NameCell,
  TypeCell,
  DataCell,
  NotifyTypeCell,
  ParamsCell,
  ActionCell
} from './components';

export default {
  name: 'RulesTable',
  components: {
    NameCell,
    TypeCell,
    DataCell,
    NotifyTypeCell,
    ParamsCell,
    ActionCell
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    searchQuery: {
      type: String,
      default: ''
    },
    activeRowRuleId: {
      type: String,
      default: ''
    },
    selectedRowRulesIds: {
      type: Array,
      required: true
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isOneColMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTableColumnHidden: {
        name: false,
        type: false,
        data: false,
        notifyType: false,
        params: false,
        action: false
      }
    };
  },
  watch: {
  },
  computed: {
    columns: {
      get() {
        return generateTableColumns({
          i18nInstance: this.$i18n,
          hiddenColumnRules: {
            name: this.isTableColumnHidden.name,
            type: this.isTableColumnHidden.type || this.isOneColMode,
            data: this.isTableColumnHidden.data || this.isOneColMode,
            notifyType: this.isTableColumnHidden.notifyType || this.isOneColMode,
            params: this.isTableColumnHidden.params || this.isOneColMode,
            action: this.isTableColumnHidden.action || this.isOneColMode || this.isOperatorRole
          },
          additionalRules: {}
        });
      },
      set(updatedColumns) {
        // обновить таблицу, когда обновились данные в колонках
        // например, какую-то колонку скрыли/раскрыли
        updatedColumns.forEach(el => {
          this.isTableColumnHidden[el.field] = el.hidden;
        });
      }
    },
    rows() {
      return generateTableRows({
        rulesData: this.items,
        selectedRowRulesIds: this.selectedRowRulesIds
      });
    },
    isOperatorRole() {
      return this.$store.state.userData.role === 'operator';
    }
  },
  methods: {
    handleUpdateColumns(columns) {
      this.columns = columns;
    },
    rowStyleClassFn(row) {
      const rowId = row.nativeData.id;

      return this.activeRowRuleId === rowId ? 'rules-table__active-row' : '';
    },
    handleSelectedAllRowsChange(event) {
      this.$emit('on-select-all', event);
    },
    handleSelectedRowsChange(event) {
      const { selectedRows } = event;
      this.$emit('on-selected-rows-change', selectedRows);
    },
    handleCellClick(event) {
      this.$emit('on-cell-click', event.row);
    },
    handlePageChange(event) {
      this.$emit('on-page-change', event);
    },
    handlePerPageChange(event) {
      this.$emit('on-per-page-change', event);
    },
    onSortChange(event) {
      this.$emit('on-sort-change', event);
    },
    handleShowMoreButtonClick(rowData) {
      this.$emit('on-show-more-rule', rowData);
    },
    handleDeleteRule(rowData) {
      this.$emit('on-delete-rule', rowData);
    }
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
</style>

<style lang="css">
.rules-table__active-row {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent);
  color: var(--font-color-main);
}

.rules-table__active-row:hover {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent) !important;
  color: var(--font-color-main) !important;
}

.rules-table__action-cell {
  text-align: center;
  vertical-align: middle !important;
}
</style>
