import { render, staticRenderFns } from "./ModelCell.vue?vue&type=template&id=167128da&scoped=true&"
import script from "./ModelCell.vue?vue&type=script&lang=js&"
export * from "./ModelCell.vue?vue&type=script&lang=js&"
import style0 from "./ModelCell.vue?vue&type=style&index=0&id=167128da&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "167128da",
  null
  
)

export default component.exports