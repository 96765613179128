<template>
  <div v-if="visibilityCondition"
    class="rules-cell"
  >
    <span
      v-for="rule in props.row.rules"
      :key="rule.id"
      class="badge badge-success"
    >
      {{ rule.name }}
    </span>

  </div>

  <span v-else
    class="badge badge-default"
  >
    {{ $t('rules.noRules') }}
  </span>
</template>

<script>
/**
 * компонент - ячейка rules.
 * компонент локальный: используется только в RulesAPsTable.vue
 * @component
 */

export default {
  name: 'RulesCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    visibilityCondition() {
      return Boolean(this.props.row.rules?.length);
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.rules-cell {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
</style>
