<template>
  <WModalContent
    :isOpen="isOpen"
    @close="handleClose"
  >
    <template #header>
      <h4 class="heading">
        {{ `${$t('dashboard.rule')}: ${ruleInfo.name ? ruleInfo.name : ruleInfo.id}` }}
      </h4>
    </template>
    <template #body>
      <div class="body-content">
        <p class="info-row-text">
          <span>
            {{ $t('rules.notificationTypeCaption') }}:
          </span>
          <span>
            {{ notifyTypeCaption }}
          </span>
        </p>
        <template v-if="notifyType === 'telegram'">
          <p class="info-row-text">
            <span>
              {{ $t('rules.notifySettingsTelegramTokenCaption') }}:
            </span>
            <span>
              {{ ruleInfo.notify_settings.token }}
            </span>
            <WButton link sm v-tooltip="$t('rules.notifySettingsTelegramTokenInfo')">
              <i class="fa fa-question-circle-o" />
            </WButton>
          </p>
          <p class="info-row-text">
            <span>
              {{ $t('rules.notifySettingsTelegramChatIdCaption') }}:
            </span>
            <span>
              {{ ruleInfo.notify_settings.chat_id }}
            </span>
            <WButton link sm v-tooltip="$t('rules.notifySettingsTelegramChatIdInfo')">
              <i class="fa fa-question-circle-o" />
            </WButton>
          </p>
          <p class="info-row-text">
            <span>
              {{ $t('rules.notifySettingsTelegramTextCaption') }}:
            </span>
            <span>
              {{ ruleInfo.notify_settings.text }}
            </span>
          </p>
        </template>

        <template v-if="notifyType === 'email'">
          <p class="info-row-text">
            <span>
              {{ $t('rules.notifySettingsEmailToCaption') }}:
            </span>
            <span>
              {{ ruleInfo.notify_settings.to }}
            </span>
          </p>
          <p class="info-row-text">
            <span>
              {{ $t('rules.notifySettingsEmailSubjectCaption') }}:
            </span>
            <span>
              {{ ruleInfo.notify_settings.subject }}
            </span>
          </p>
          <p class="info-row-text">
            <span>
              {{ $t('rules.notifySettingsEmailTextCaption') }}:
            </span>
            <span>
              {{ ruleInfo.notify_settings.text }}
            </span>
          </p>
        </template>

        <template v-if="notifyType === 'mqtt'">
          <p class="info-row-text">
            <span>
              {{ $t('rules.notifySettingsMQTTTopicCaption') }}:
            </span>
            <span>
              {{ ruleInfo.notify_settings.topic }}
            </span>
            <WButton link sm v-tooltip="$t('rules.notifySettingsMQTTTopicInfo') ">
              <i class="fa fa-question-circle-o" />
            </WButton>
          </p>
          <p class="info-row-text">
            <span>
              {{ $t('rules.notifySettingsMQTTTextCaption') }}:
            </span>
            <span>
              {{ ruleInfo.notify_settings.text }}
            </span>
          </p>
        </template>
      </div>
    </template>
    <template #footer>
      <div class="button-section">
        <WButton secondary
          @click="handleClose"
        >
          {{ $t('general.close') }}
        </WButton>
      </div>
    </template>
  </WModalContent>
</template>

<script>
/**
 * компонент - модалка для простотра дополнительной информации о rule.
 * компонент локальный: используется только в Rules.vue
 * @component
 */

export default {
  name: 'RuleMoreInfoModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    ruleInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    notifyType() {
      return this.ruleInfo.notify_type;
    },
    notifyTypeCaption() {
      switch (this.notifyType) {
        case 'telegram':
          return this.$t('rules.notificationTypes.telegram');
        case 'email':
          return this.$t('rules.notificationTypes.email');
        case 'mqtt':
          return this.$t('rules.notificationTypes.mqtt');
        case '':
          return this.$t('rules.script');
        default:
          return this.notifyType;
      }
    }
  },
  watch: {

  },
  methods: {
    handleClose() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="css" scoped>
  h4, h5 {
    margin: 0;
  }

  .body-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }

  .info-row-text {
    font-size: 16px;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .info-row-text > *:first-child {
    margin-right: 6px;
    font-weight: bold;
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
