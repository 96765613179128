<template>
  <span>
    {{ notifyType }}
  </span>
</template>

<script>
/**
 * компонент - ячейка notifyType.
 * компонент локальный: используется только в RulesTable.vue
 * @component
 */

export default {
  name: 'NotifyTypeCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    notifyType() {
      const { notifyType } = this.props.row;

      switch (notifyType) {
        case 'telegram':
          return this.$t('rules.notificationTypes.telegram');
        case 'email':
          return this.$t('rules.notificationTypes.email');
        case 'mqtt':
          return this.$t('rules.notificationTypes.mqtt');
        case '':
          return this.$t('rules.script');
        default:
          return notifyType;
      }
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
</style>
