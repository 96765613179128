<template>
  <span>
    {{ props.row.type ? $t(`rules.ruleTypes.${props.row.type}`) : '-' }}
  </span>
</template>

<script>
/**
 * компонент - ячейка type.
 * компонент локальный: используется только в RulesTable.vue
 * @component
 */

export default {
  name: 'NameCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
</style>
