/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы RulesTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('general.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('general.type'),
      field: 'type',
      width: '20%',
      type: 'text',
      hidden: hiddenColumnRules.type
    },
    {
      label: $t('monitor.data'),
      field: 'data',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.data
    },
    {
      label: $t('rules.notificationTypeCaption'),
      field: 'notifyType',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.notifyType
    },
    {
      label: $t('rules.notifySettingsForTableCaption'),
      field: 'params',
      width: '25%',
      type: 'text',
      sortable: false,
      hidden: hiddenColumnRules.params
    },
    {
      label: '',
      field: 'action',
      width: 'auto',
      type: 'text',
      sortable: false,
      tdClass: 'rules-table__action-cell',
      hidden: hiddenColumnRules.action,
      visibilityDropdownSettings: {
        hidden: true
      }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ rulesData, selectedRowRulesIds }) {
  return rulesData.map(rule => {
    const {
      base_location,
      data,
      id,
      name,
      notify_settings,
      notify_type,
      post_script,
      tags,
      type
    } = rule;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: rule,
      name,
      type,
      data,
      notifyType: notify_type,
      params: {
        notify_type,
        notify_settings,
        post_script
      },
      action: '',

      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedRowRulesIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
