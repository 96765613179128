<template>
  <span v-if="!isCustomeractivityType && props.row.data">
    {{ props.row.data.lower }} - {{ props.row.data.upper }}
  </span>

  <span v-else-if="isCustomeractivityType && props.row.data.lower">
    {{ props.row.data.lower }} {{ $t('rules.minTrafficThresholdCaptionBytesForTableColumn') }}
  </span>

  <span v-else>-</span>
</template>

<script>
/**
 * компонент - ячейка data.
 * компонент локальный: используется только в RulesTable.vue
 * @component
 */

export default {
  name: 'DataCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    isCustomeractivityType() {
      return this.props.row.nativeData.type === 'customer_activity';
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.muted {
  color: var(--gray-light);
}
</style>
