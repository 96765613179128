// selected checkbox of a row or not
function getSelectedRowValue(currentRuleId, selectedRulesIdsArray) {
  let value = false;

  selectedRulesIdsArray.forEach(ruleId => {
    if (ruleId === currentRuleId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
