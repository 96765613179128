<template>
  <WButton danger squared sm
    @click="handleDeleteButtonClick"
  >
    <i class="fa fa-close"></i>
  </WButton>
</template>

<script>
/**
 * компонент - ячейка action.
 * компонент локальный: используется только в RulesTable.vue
 * @component
 */

export default {
  name: 'ActionCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  methods: {
    handleDeleteButtonClick(event) {
      this.$emit('on-delete-click', event);
    }
  }
};
</script>

<style lang="css" scoped>
</style>
