<template>
  <WModalContent
    :isOpen="isOpen"
    @close="handleClose"
  >
    <template #header>
      <h4 class="heading">
        {{ `${$t('monitor.modal.applyRules.header')}: ${cpeInfo.name ? cpeInfo.name : cpeInfo.id}` }}
      </h4>
    </template>
    <template #body>
      <div v-if="!isRulesListEmpty">
        <div class="explanation">
          {{ `${$t('monitor.modal.applyRules.label')}:` }}
        </div>
        <div class="rules-section">
          <WButton sm outline
            v-for="rule in rulesList"
            :success="rulesIdsListToApply.includes(rule.id)"
            :secondary="!rulesIdsListToApply.includes(rule.id)"
            :key="rule.id"
            @click="handleClickRuleButton(rule.id)"
          >
            {{ rule.name }}
            <i
              class="fa icon"
              :class="getRuleIconClass(rule.id)"
            />
          </WButton>
        </div>
      </div>
      <div v-else
        class="empty-state-container"
      >
        <h5>
          {{ $t('monitor.noRules') }}
        </h5>
        <div>
          <WButton @click="handleRuleButtonClick">
            {{ $t('monitor.addRule') }}
          </WButton>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="button-section">
        <WButton secondary
          @click="handleClose"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton success
          @click="handleApply"
          v-if="!isRulesListEmpty"
        >
          {{ $t('general.apply') }}
        </WButton>
      </div>
    </template>
  </WModalContent>
</template>

<script>
/**
 * компонент - модалка для редактирования rules для cpe.
 * компонент локальный: используется только в Rules.vue
 * @component
 */

export default {
  name: 'ApplyRulesToCPEModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    cpeInfo: {
      type: Object,
      required: true
    },
    rulesList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      rulesIdsListToApply: []
    };
  },
  computed: {
    isRulesListEmpty() {
      return Boolean(!this.rulesList.length);
    }
  },
  watch: {
    cpeInfo(newVal) {
      if (newVal?.rules?.length) {
        this.rulesIdsListToApply = newVal.rules.map(rule => rule.id);
      } else {
        this.rulesIdsListToApply = [];
      }
    }
  },
  methods: {
    getRuleIconClass(ruleId) {
      const isRuleAppliedToCpe = this.rulesIdsListToApply.includes(ruleId);

      return [
        isRuleAppliedToCpe && 'fa-check'
      ];
    },
    handleClose() {
      this.$emit('close');
    },
    handleClickRuleButton(ruleIdToToggle) {
      const isCurrentRuleApplied = this.rulesIdsListToApply.includes(ruleIdToToggle);

      if (isCurrentRuleApplied) {
        const result = [...this.rulesIdsListToApply]
          .filter(appliedId => appliedId !== ruleIdToToggle);

        this.rulesIdsListToApply = result;
      }

      if (!isCurrentRuleApplied) {
        const result = [...this.rulesIdsListToApply, ruleIdToToggle];
        this.rulesIdsListToApply = result;
      }
    },
    handleApply() {
      const { id } = this.cpeInfo;
      this.$emit('apply', {
        cpeId: id,
        newRulesIdsToApply: this.rulesIdsListToApply
      });
    },
    handleRuleButtonClick(event) {
      this.$emit('on-add-rule-button-click', event);
    }
  }
};
</script>

<style lang="css" scoped>
  h4, h5 {
    margin: 0;
  }

  .explanation {
    margin-bottom: 8px;
  }

  .rules-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    padding: 16px 20px;
    border: 1px solid var(--border-color);
    border-radius: var(--modal-border-radius);
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .empty-state-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
</style>
