// selected checkbox of a row or not
function getSelectedRowValue(currentAPId, selectedApsIdsArray) {
  let value = false;

  selectedApsIdsArray.forEach(clientId => {
    if (clientId === currentAPId) {
      value = true;
    }
  });

  return value;
}

export {
  getSelectedRowValue
};
