<template xmlns:v-clipboard="http://www.w3.org/1999/xhtml">
  <div class="row">
    <div :class="{ 'operator-mode': isOperatorRole }" class="col-lg-12 d-flex justify-content-end mb-h">
      <WButton
        v-if="!isOperatorRole"
        @click="openRuleWizard"
      >
        {{ $t('monitor.addRule') }}
      </WButton>
    </div>
    <div class="col-lg-12" :class="{ 'operator-mode': isOperatorRole }">
      <ul class="nav nav-tabs row m-0 tabs-in-rules-page" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            role="tab"
            :class="{ active: currentTab === 0 }"
            @click.prevent="currentTab = 0"
          >
            <i class="fa fa-align-justify"></i>
            {{ $t('general.cpes') }}
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            data-toggle="tab"
            role="tab"
            :class="{ active: currentTab === 1 }"
            @click.prevent="currentTab = 1"
          >
            <i class="fa fa-align-justify"></i>
            {{ $t('general.rules') }}
          </a>
        </li>
      </ul>
      <div class="tab-content row m-0" :class="{'left-tab-selected-in-rules-page': currentTab === 0 }">
        <div class="tab-pane col-lg-12" id="tab1" role="tabpanel" :class="{ active: currentTab === 0 }">
          <div v-if="isShowingSpinnerOverTable">
            <loader-spinner></loader-spinner>
          </div>
          <RulesAPsTable
            :items="cpesListWithRules || []"
            :searchQuery="filters.query"
            :selectedRowAPsIds="[]"
            :activeRowAPsId="''"
            :isDataLoading="false"
            :isDisabled="false"
            :isOneColMode="false"
            :isSelectionRowsModeEnabled="false"
            @on-cell-click="toggleEditRule"
          >
            <template v-slot:table-menu>
              <div class="control-panel">
                <div class="control-panel__container left">
                  <div class="search-input-container">
                    <WInput md
                      :customStyle="{borderRadius: '8px !important'}"
                      id="rules-aps-table-search"
                      :placeholder="$t('general.searchPlaceHolder')"
                      name="rules-aps-table-search"
                      v-model.trim="rulesAPsTableSearchValue"
                      data-vv-validate-on="input"
                      :data-vv-as="$t('general.search')"
                    >
                      <template #icon-right>
                        <button
                          v-show="rulesAPsTableSearchValue"
                          class="reset-button"
                          @click.stop="resetSearchValue"
                          :disabled="isShowingSpinnerOverTable"
                        >
                          <i class="fa fa-close"></i>
                        </button>
                      </template>
                    </WInput>
                  </div>
                </div>
                <div class="control-panel__container right">
                </div>
              </div>
            </template>
            <template v-slot:emptystate>
              <div class="emptystate-table-row muted">
                {{ $t('general.noDataToDisplay') }}
              </div>
            </template>
            <template v-slot:table-actions-bottom>
            </template>
          </RulesAPsTable>
        </div>
        <div class="tab-pane col-lg-12" id="tab2" role="tabpanel" :class="{ active: currentTab === 1 }">
          <div v-if="isShowingSpinnerOverTable">
            <loader-spinner></loader-spinner>
          </div>
          <RulesTable
            :items="rulesList || []"
            :searchQuery="filtersForRules.query"
            :selectedRowRulesIds="[]"
            :activeRowRuleId="''"
            :isDataLoading="false"
            :isDisabled="false"
            :isOneColMode="false"
            :isSelectionRowsModeEnabled="false"
            @on-show-more-rule="handleShowMoreRule"
            @on-delete-rule="handleDeleteRule"
          >
            <template v-slot:table-menu>
              <div class="control-panel">
                <div class="control-panel__container left">
                  <div class="search-input-container">
                    <WInput md
                      :customStyle="{borderRadius: '8px !important'}"
                      id="rules-aps-table-search"
                      :placeholder="$t('general.searchPlaceHolder')"
                      name="rules-aps-table-search"
                      v-model.trim="rulesTableSearchValue"
                      data-vv-validate-on="input"
                      :data-vv-as="$t('general.search')"
                    >
                      <template #icon-right>
                        <button
                          v-show="rulesTableSearchValue"
                          class="reset-button"
                          @click.stop="resetSearchValue"
                          :disabled="isShowingSpinnerOverTable"
                        >
                          <i class="fa fa-close"></i>
                        </button>
                      </template>
                    </WInput>
                  </div>
                </div>
                <div class="control-panel__container right">
                </div>
              </div>
            </template>
            <template v-slot:emptystate>
              <div class="emptystate-table-row muted">
                {{ $t('general.noDataToDisplay') }}
              </div>
            </template>
            <template v-slot:table-actions-bottom>
            </template>
          </RulesTable>
        </div>
      </div>

      <!-- модалка добавления/удаления правил на точке -->
      <ApplyRulesToCPEModal
        :isOpen="isApplyRulesToCPEModalOpen"
        :cpeInfo="selectedCpeInfo"
        :rulesList="rulesList"
        @close="closeApplyRulesToCPEModal"
        @apply="handleApplyCpeRules"
        @on-add-rule-button-click="handleOnAddRuleButtonClick"
      />

      <!-- модалка для простотра дополнительной информации о rule -->
      <RuleMoreInfoModal
        :isOpen="isRuleMoreInfoModalOpen"
        :ruleInfo="ruleInfo"
        @close="closeRuleMoreInfoModal"
      />

      <modal
        title="Delete Rule"
        class="modal-danger"
        v-model="deletingRuleModal"
        okText="Confirm"
        cancelText="Cancel"
        @ok="deleteRule"
        @cancel="cancelDeleting"
        effect="fade/zoom"
      >
        <h4 slot="modal-title" class="modal-title">{{ $t('monitor.ruleDelete') }}</h4>
        <p v-if="ruleIdToDelete">
          {{ $t('monitor.ruleConfirmDeleting') }}
          <strong>
            {{
              commonService.ruleNamebyId(ruleIdToDelete)
                ? commonService.ruleNamebyId(ruleIdToDelete).name
                : ruleIdToDelete
            }}
          </strong>
        </p>
        <div slot="modal-footer" class="modal-footer">
          <WButton secondary
            @click="deletingRuleModal = false"
          >
            {{ $t('general.cancel') }}
          </WButton>
          <WButton danger outline
            @click="deleteRule"
          >
            {{ $t('general.delete') }}
          </WButton>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import Modal from '@/components/Modal.vue';
import ruleService from '@/services/ruleService';
import cpeService from '@/services/cpeService';
import commonService from '@/services/commonService';

import {
  RulesTable,
  RulesAPsTable,
  ApplyRulesToCPEModal,
  RuleMoreInfoModal
} from './components';

import {
  intervalUpdateModeMixin,
  debounceMixin
} from '@/mixins';

export default {
  name: 'Rules',
  components: {
    Modal,
    RulesTable,
    RulesAPsTable,
    ApplyRulesToCPEModal,
    RuleMoreInfoModal
  },
  mixins: [
    intervalUpdateModeMixin,
    debounceMixin
  ],
  data() {
    return {
      isApplyRulesToCPEModalOpen: false,
      isRuleMoreInfoModalOpen: false,
      rulesAPsTableSearchValue: '',
      rulesTableSearchValue: '',
      filters: {
        query: ''
      },
      filtersForRules: {
        query: ''
      },
      inputInRulesSerchTimeout: null,
      deletingRuleModal: false,
      ruleList: [],
      cpeRules: [],
      tempCpeRules: [],
      selectedCpeInfo: {},
      ruleInfo: {},
      ruleIdToDelete: '',
      currentTab: 0
    };
  },
  computed: {
    commonService() {
      return commonService;
    },
    ruleService() {
      return ruleService;
    },
    isRulesLoading() {
      return this.$store.state.loadingRules;
    },
    isCpesRulesLoading() {
      return this.$store.state.loadingCpesRules;
    },
    isCpesLoading() {
      return this.$store.state.loadingCpes;
    },
    isShowingSpinnerOverTable() {
      return this.isRulesLoading || this.isCpesRulesLoading || this.isCpesLoading;
    },
    cpesList: {
      get() {
        return this.$store.state.cpesList;
      },
      set() {}
    },
    rulesList() {
      return this.$store.state.rulesList ? this.$store.state.rulesList : [];
    },
    cpesRulesList: {
      get() {
        return this.$store.state.cpesRulesList || {};
      },
      set() { }
    },
    // cpesList с добавленным полем rules
    // поле rules по умолчанию не входит, с бэка одним запросом не приходит, надо мержить
    cpesListWithRules() {
      // список cpes без rules
      const cpesList = this.cpesList || [];
      // объект-словарь, где указывается, есть ли у cpe примененные rules из (но только id rules, без тела)
      const cpesDictWithAppliedRulesInfo = this.cpesRulesList || {};
      // cписок rules с телами
      const rulesList = this.rulesList || [];

      const cpesListWithRules = cpesList.map(cpe => {
        const cpesId = cpe.id;

        const rulesAppliedToCpeIdsArray = cpesDictWithAppliedRulesInfo[cpesId]?.rules;
        const areRulesAppliedToCpe = Boolean(rulesAppliedToCpeIdsArray?.length);

        if (!areRulesAppliedToCpe) {
          return {
            ...cpe,
            rules: []
          };
        }

        const appliedRulesToCpeArray = rulesAppliedToCpeIdsArray
          .map(ruleId => {
            const currentRuleInfo = rulesList.find(ruleInfo => {
              return ruleInfo.id === ruleId;
            });
            return currentRuleInfo;
          })
          // требуется еще и отфильтровать undefined, тк после удаления rule, который был включен у cpe,
          // cpesDictWithAppliedRulesInfo не обновляется.Хз как ее обновить в текущем коде.
          // todo решить
          .filter(ruleId => ruleId !== undefined);

        return {
          ...cpe,
          rules: appliedRulesToCpeArray
        };
      });

      return cpesListWithRules;
    },
    isOperatorRole() {
      return this.$store.state.userData.role === 'operator';
    }
  },
  methods: {
    openApplyRulesToCPEModal() {
      this.isApplyRulesToCPEModalOpen = true;
    },
    closeApplyRulesToCPEModal() {
      this.isApplyRulesToCPEModalOpen = false;
      this.selectedCpeInfo = {};
    },
    openRuleMoreInfoModal() {
      this.isRuleMoreInfoModalOpen = true;
    },
    closeRuleMoreInfoModal() {
      this.isRuleMoreInfoModalOpen = false;
      this.ruleInfo = {};
    },
    handleApplyCpeRules(cpeDataToApply) {
      const { cpeId, newRulesIdsToApply } = cpeDataToApply;

      this.tempCpeRules.push({
        cpeId,
        rules: newRulesIdsToApply
      });

      ruleService.createCpeRules(this);

      this.closeApplyRulesToCPEModal();
    },
    toggleEditRule(rulesAPsTableRowData) {
      if (this.isOperatorRole) {
        return;
      }

      const { nativeData } = rulesAPsTableRowData;
      this.selectedCpeInfo = nativeData;

      this.openApplyRulesToCPEModal();
    },
    handleShowMoreRule(rulesAPsTableRowData) {
      const {
        nativeData:
        {
          name,
          id,
          notify_settings,
          notify_type,
          post_script
        }
      } = rulesAPsTableRowData;

      this.ruleInfo = {
        name,
        id,
        notify_settings,
        notify_type,
        post_script
      };

      this.openRuleMoreInfoModal();
    },
    handleDeleteRule(rulesAPsTableRowData) {
      const { nativeData: { id } } = rulesAPsTableRowData;

      this.openDeletingDialog(id);
    },
    resetSearchValue() {
      if (this.currentTab === 0) {
        this.filters.query = '';
        this.rulesAPsTableSearchValue = '';
      }

      if (this.currentTab === 1) {
        this.filtersForRules.query = '';
        this.rulesTableSearchValue = '';
      }
    },
    handleOnAddRuleButtonClick() {
      this.closeApplyRulesToCPEModal();
      this.openRuleWizard();
    },
    openRuleWizard() {
      this.$router.push({ name: 'rule-wizard' });
    },
    openDeletingDialog(id) {
      this.deletingRuleModal = true;
      this.ruleIdToDelete = id;
    },
    deleteRule() {
      this.deletingRuleModal = false;
      ruleService.deleteRule(this);
      this.ruleIdToDelete = '';
    },
    cancelDeleting() {
      this.ruleIdToDelete = '';
    },
    refreshRulesList() {
      ruleService.getRules(this);
    },
    refreshCpeRuleList() {
      ruleService.refreshCpesRules(this);
    },
    setFilter(filterName, value) {
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
        return;
      }
      this.filters[filterName] = '';
    }
  },
  watch: {
    currentTab() {
      this.resetSearchValue();
    },
    rulesAPsTableSearchValue(newQueryValue) {
      this.debounce(() => {
        this.filters.query = newQueryValue;
      }, 1000);
    },
    rulesTableSearchValue(newQueryValue) {
      this.debounce(() => {
        this.filtersForRules.query = newQueryValue;
      }, 1000);
    }
  },
  created() {
    ruleService.getRules(this);
    ruleService.getCpesRules(this);
    // cpeService.getAllCompactCpes(this);
    cpeService.getAllCompactCpesWithDataRemaining(this);
  },
  mounted() {
    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      this.refreshRulesList();
      this.refreshCpeRuleList();

      // ruleService.getRules(this);
      // ruleService.getCpesRules(this);
      // cpeService.getAllCompactCpesWithDataRemaining(this);
    });
  }
};
</script>

<style lang="css" scoped>
h4,
h5 {
  margin: 0;
}

.button-section {
  display: flex;
  justify-content: space-between;
}

.reset-button {
  color: var(--font-color);
  background: transparent;
  border: none;
  transition: var(--transition);
}

.reset-button:hover {
  color: var(--brand-danger);
}

.search-input-container {
  max-width: 220px;
  min-width: 200px;
}

.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}

.emptystate-table-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.muted {
  color: var(--gray-light);
}
</style>
