/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы RulesAPsTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: $t('general.name'),
      field: 'name',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.name
    },
    {
      label: $t('general.model'),
      field: 'model',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.model
    },
    {
      label: $t('monitor.rules'),
      field: 'rules',
      width: '30%',
      type: 'text',
      sortable: false,
      hidden: hiddenColumnRules.rules
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ apsData, selectedRowAPsIds }) {
  return apsData.map(ap => {
    const {
      base_location,
      config,
      config_status,
      connected,
      description,
      id,
      model,
      name,
      rules,
      state,
      tags
    } = ap;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: ap,
      name,
      model: model?.name,
      rules,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedRowAPsIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
